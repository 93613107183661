export default Object.freeze([
	{name: "1.購入者への問い合わせ中", value: "01"},
	{name: "2.支払待ち", value: "02"},
	{name: "3.発送待ち", value: "03"},
	{name: "4.発送完了連絡待ち", value: "04"},
	{name: "5.到着連絡待ち", value: "05"},
	{name: "6.到着", value: "06"},
	{name: "7.振込申請済", value: "07"},
	{name: "8.送金確認申請済", value: "08"},
	{name: "9.送金済", value: "09"},
	{name: "10.却下", value: "10"},
])
